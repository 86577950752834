export default {
  init() {
    // JavaScript to be fired on the eto nas us page
    const axios = require('axios');
    const API_CLIENT = `https://rtvpuls.com/wp-json/wp/v2/program`;
    const LANG = window.CURRENT_LANG;
    const MOUNT_POINT = document.getElementById('ajax-blog');
    const NO_FOUND_MSG = document.getElementById('notfoundtext-blog');
    const LOADER = document.getElementById('loader-blog');
    const LOAD_MORE = document.getElementById('ajax-blog-loadmore');
    const ALL_LOADED = document.getElementById('allloaded-blog');
    let BLOG_LIST = [];
    let settings = {
      per_page: 12,
      page: 1,
    };

    let trimString = function (string, length) {
      return string.length > length ? string.substring(0, length) + '...' : string;
    };

    function renderArticle(client) {
      let blogHtml = '';
      client.forEach((categoryPost) => {
        BLOG_LIST.push(categoryPost);
        blogHtml += `
              <a class="row-item" href="${categoryPost.link}">
                <div class="thumb">
                   <img loading="lazy" src="${categoryPost._embedded['wp:featuredmedia'][0].source_url}" alt="${categoryPost._embedded['wp:featuredmedia'][0].alt_text}" />
                </div>
                <div class="content">
                  <h2>${categoryPost.title.rendered}</h2>
                  <p class="excerpt">${trimString(categoryPost.title.rendered, 100)}</p>
                </div>
              </a>        
        `;
      });
      MOUNT_POINT.innerHTML += blogHtml;
      LOADER.style.display = 'none';
    }
    function fetchBlog(page) {
      LOADER.style.display = 'block';
      let data = {};
      //Current cat from php var
      if (typeof CURRENT_POST_CAT !== 'undefined') {
        data['categories'] = CURRENT_POST_CAT;
      }
      data['per_page'] = settings.per_page;
      data['page'] = page;
      data['categories'] = 5; //cat id
      //Excluded from php array
      data['exclude'] = excluded_blog_id;
      data['_embed'] = 1;
      data['lang'] = LANG;

      axios
        .get(API_CLIENT, {
          params: data,
        })
        .then((response) => {
          var totalPages = response.headers && response.headers['x-wp-totalpages'];
          if (page == totalPages) {
            LOAD_MORE.style.display = 'none';
            ALL_LOADED.style.display = 'block';
          } else {
            LOAD_MORE.style.display = 'block';
          }
          if (totalPages < 1) {
            LOADER.style.display = 'none';
          }
          if (response.data.length == 0) {
            NO_FOUND_MSG.style.display = 'block';
          } else {
            NO_FOUND_MSG.style.display = 'none';
          }
          renderArticle(response.data);
        })
        .catch((e) => {
          LOADER.style.display = 'none';
          console.log(e);
        });
    }
    if (LOAD_MORE) {
      LOAD_MORE.addEventListener('click', (event) => {
        fetchBlog(settings.page);
        settings.page++;
      });
    }
  },
};
